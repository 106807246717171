<template>
  <footer class="footer">
    <div id="inner-container">
      <p>Copyright © Állatszerviz Kft., 2025.</p>
      <p>A honlap információi felhasználhatók a forrás megjelölésével. A honlap eredeti tartalma, illetve oldalainak bármilyen alkotóeleme (szöveg, kép, hang, videó, dokumentum, stb.) csak az Állatszerviz Kft. előzetes, írásbeli engedélyével használható fel.</p>
      <!--                           ↙ A `@click` akkor is működik, ha tabos navigálással nyomjuk meg, meg akkor gondolom minden más módszerrel is -->
      <router-link to="/ceginfo" @click="scrollToTop">Állatszerviz Kft. céginfó</router-link>
      <hr>
      <p>A weboldalt készítette <b>Paál Donát</b> a <a href="https://vuejs.org/" target="_blank">Vue.js framework</a> segítségével.</p>
      <p>A weboldallal kapcsolatos hibabejelentéseket vagy más típusú üzeneteket az <router-link to="/elerhetoseg" @click="scrollToTop">elérhetőség oldalon</router-link> lévő e-mail címre fogadunk.</p>
      <hr>
      <p>Szerverkezelés, külön köszönet: <b>Gróf "krisz-foto" Krisztián</b></p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    // Ha másik oldalon vagyunk, mint amire mutat a router-link, ami meghívja ezt, akkor mire fel tudna görgetni ez a függvény, már rég átnavigált a
    // másik oldalra, viszont ha nem, akkor a Vue simán ignorálja a navigálást, így ez a függvény "kényelmesen" fel tud görgetni.
    scrollToTop() {
      window.scrollTo({top: 0, behavior: "smooth"});
    }
  }
}
</script>

<style scoped>
  footer {
    bottom: 0;
    width: 100%;
    margin-top: 50px;
    background-color: hsl(0, 0%, 25%);
    color: hsl(0, 0%, 75%);
    box-shadow: rgba(0, 0, 0, .75) 0 0 15px;
  }

  a {
    color: white;
    transition: color 0.2s;
  }

  a:hover {
    color: #75bcff;
  }

  hr {
    height: 1px;
    border: none;
    border-bottom: hsl(0, 0%, 50%) dotted 1px;
  }

  #inner-container {
    display: block; margin-left: auto; margin-right: auto; /* Középre igazítás */
    width: 90%; /* 100%-nál és ahhoz közel valamiért overflow-ol, szóval ettől kicsit jobban néz ki, és meg is oldja a problémát */
    max-width: 1500px;
    padding: 5px;
  }
</style>