<template>
  <BemPersonModal
    v-if="mobileMode"

    v-model:is-open="mobileModal.isOpen"
    :name="mobileModal.name"
    :role="mobileModal.role"
    :html="mobileModal.html"
  />

  <div class="bemutatkozas">
    <div id="person_container" v-for="person in personnel" :key="person.name">
      <BemPersonMobile v-if="mobileMode" :image="person.image" :name="person.name" :role="person.role" :html="person.html" @click="openMobileModal(person.name, person.role, person.html)"/>
      <BemutatkozasPerson v-else :image="person.image" :name="person.name" :role="person.role" :html="person.html"/>
    </div>
  </div>      
</template>

<script>
import BemutatkozasPerson from '@/components/BemutatkozasPerson.vue'
import BemPersonMobile from '@/components/BemPersonMobile.vue'
import BemPersonModal from '@/components/BemPersonModal.vue'

export default {
  components: {
    BemutatkozasPerson,
    BemPersonMobile,
    BemPersonModal
  },
  data() {
    return {
      personnel: [
        {
          image: "drMatyovszkyBalazs.jpg",
          name: "dr. Matyovszky Balázs",
          // role: "állatorvos, kérődző-egészségügyi szakállatorvos, az Állatorvostudományi Egyetem gyakorlati képzés mesteroktatója",
          role: "állatorvos, alapító",
          html: "<p>1998-ban az Állatorvostudományi Egyetem elvégzése után 1 évig budapesti kisállatpraxisokban dolgozott, majd szülővárosába visszaköltözve nagyüzemi szarvasmarhatelep ellátó állatorvosa lett. Párhuzamosan kisállatpraxis felépítésébe is kezdett, ami a pincében kialakított rendelőből mára egy 2 vizsgálós állatorvosi komplexummá nőtte ki magát. Manapság a fő tevékenységi köre a kisállatgyógyászat, azon belül a lágyszervi sebészet és az ultrahang-diagnosztika jelent kiemelt kihívásokat számára. Az Állatszerviz Kft. társtulajdonosa. A napi munka irányítása is kiemelt feladata.</p><p><b>Tudományos munkák:</b></p><ul><li>A paratuberkulózis kártétele és az ellene való védekezés egy hazai nagyüzemi holstein-fríz tehenészetben (I. Fodor – B. Matyovszky – A. Biczó – L. Ózsvári: The losses due to paratuberculosis and its control in a Hungarian large-scale Holstein-Friesian dairy farm) MÁL 2014/4</li><li>A bal oldali oltógyomor-helyzetváltozás (OHV) által okozott veszteségek és a műtéti megoldás gazdasági elemzése egy nagy létszámú tehenészetben (I. Fodor – B. Matyovszky –A. Biczó – L. Ózsvári: The economic impact of left displaced abomasum and decision analysis of the surgical correction on a large-scale dairy farm) MÁL 2015/7 </li></ul>"
        },
        {
          image: "horvathAdrienn.jpg",
          name: "Horváth Adrienn",
          role: "asszisztens, alapító",
          html: "<p>Kertészmérnöki diplomája megszerzése után pár évig szakmájában dolgozott, majd az Állatszerviz Kft. egyik tulajdonosaként a cég irányítása és asszisztensi tevékenységek pályamódosításra késztették. 2007 óta a Kft. adminisztratív hátterét tartja rendben, és a mindennapok gyógyító munkájában is részt vesz asszisztensként, de az arculattervezés is az ő gondolatai alapján került kialakításra.</p>"
        },
        {
          image: "drBojteAgnes.jpg",
          name: "dr. Böjte Ágnes",
          role: "állatorvos",
          html: "<p>Állatorvosi diplomáját 2015-ben vette át, majd frissdiplomásként vas megyében kezdett el dolgozni. Elsősorban szarvasmarhatelepek ellátását végezte, illetve vegyes, kijárós praxisban szerzett gyakorlatot a beteg állatok ellátásában. Később a munkája fókuszába a kutya- és macskagyógyászat került. 2019-ben egy zala megyei nagyforgalmú állatorvosi rendelő munkatársa lett. 2020-tól egyéni vállakozóként ismét  vas megye területén dolgozott. Praxisában kutyák, macskák és lovak ellátását végezte. Az Állatszerviz Kft. munkatársaival évek óta jó ismeretséget és kollegális viszonyt ápolt, aminek továbblépéseként 2023. decemberétől a zalaegerszegi rendelő munkatársa lett. Otthonában egy örökbe fogadott keverék kutyus és egy macska boldog tulajdonosa.</p>"
        },
        {
          image: "konczRebeka.jpg",
          name: "Koncz Rebeka",
          role: "asszisztens",
          html: "<p>Az Állatszerviz Kft. új rendelőbe költözésekor asszisztensi munkakörben bővítés vált szükségessé. Addig a saját Mamma Natura üzlet tulajdonosaként dolgozott, de életében egy profilváltás iránti igény jelentkezett. Gyermekkora óta szeretett volna állatokkal foglalkozni, családjában is nagy hagyománya van az állatok iránti rajongásnak. A kereslet és a kínálat szerencsésen tudott egymásra találni, és eleinte (még szakdolgozatírás mellett) a rugalmas, részmunkaidős munkalehetőség ideális volt. A fejlődő praxis fokozatosan egyre inkább teljes embert kívánt. Jelenleg adminisztráció, időpontfoglalások koordinációja, műtéti és ambuláns asszisztensi feladatok, valamint laborasszisztensi tevékenység a fő feladatköre.</p>"
        },
        {
          image: "vorosViktoria.jpg",
          name: "Vörös Viktória",
          role: "asszisztens",
          html: "<p>Gyerekkorától kezdve fontos szerepet játszanak a lovak és a kutyák az életében. Kutyusával jelenleg is többféle sportot űznek. Gyógytornászként diplomázott, azonban szeretett volna szakmája mellett állatszeretetének is hódolni. Jó lehetőségnek bizonyult, hogy korábbi asszisztensi tapasztalatait továbbfejlesztve rendelőnk csapatát erősítse.</p>"
        },
        {
          image: "szakalAndras.jpg",
          name: "Szakál András",
          role: "asszisztens",
          html: "<p>Internetes hirdetés alapján került az Állatszerviz Kft. csapatába, 2017-ben. Kifejezetten szarvasmarhatelepi munkára vettük fel. Kiváló hozzáállása, rugalmas munkavégzése, pontossága és érdeklődése alapján a kisállatpraxisba is bevontuk. Rendkívül tanulékony, nem lép túl a hatáskörén, de aktív részese a munkának. Jól kezeli az esetleges konfliktusokat.</p>"
        },
        {
          image: "berta.jpg",
          name: "Berta",
          role: "nyugalmazott önkéntes véradó és médiastatiszta",
          html: "<p>vau, vau, va-va vau.</p>"
        },
        {
          image: "rozi.jpg",
          name: "Rozi",
          role: "házimacska (felis catus)",
          html: "<p>mijaaaauuúUÚ.</p>"
        }
      ],
      mobileMode: false,
      mobileModal: {
        isOpen: false,
        name: "",
        role: "",
        html: ""
      },
    };
  },
  methods: {
    checkShouldUseMobileMode() {
      const MOBILE_MODE_WIDTH = 700;
      if (window.innerWidth < MOBILE_MODE_WIDTH) {
        this.mobileMode = true; 
      }
      else {
        this.mobileMode = false;
        this.mobileModal.isOpen = false; // v-if ide nem elég, mert az nem kapcsolja vissza a görgetést, de ez a "mobileMode.isOpen" watch-csal rendesen lekezeli
      }
    },
    openMobileModal(name, role, html) {
      if (!html) return; // Ha nincs semmi leírása (pl. Berta, Rozi), ne jelenítsünk meg egy üres modalt

      this.mobileModal.name = name;
      this.mobileModal.role = role;
      this.mobileModal.html = html;
      this.mobileModal.isOpen = true;
    }
  },
  watch: {
    "mobileModal.isOpen"(newValue) {
      if (newValue) {
        document.body.classList.add("disable_scroll");
      } else {
        document.body.classList.remove("disable_scroll");
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.checkShouldUseMobileMode);
    this.checkShouldUseMobileMode();
  }
}
</script>

<style scoped>
.bemutatkozas {
  background-image: url("~@/assets/backgrounds/bgGray.png");
  background-color: #e7e8e9;
  padding: 15px 0;
  box-shadow: rgba(0, 0, 0, 0.5) 0 0 15px;
  border-radius: 0 0 15px 15px;
  max-width: 1500px;
  display: block;
  margin: 0 auto;
}

@media only screen and (max-width: 1500px) {
  .bemutatkozas {
    border-radius: 0;
    box-shadow: none;
  }
}

@media only screen and (max-width: 1100px) {
  .bemutatkozas {
    background-image: url("~@/assets/backgrounds/bgWhite.png");
    background-color: white;
  }
}
</style>